.header {
    background-color: $main-bg;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    height: $header-height;
    transition: height 0.3s ease;

    @include tablet {
        height: $header-tablet-height;
        box-shadow: $box-shadow;
    }

    @include mobile {
        height: $header-mobile-height;
    }

    .container {
        height: 100%;
    }

    &.shrink {
        height: $header-shrink-height;//$header-shrink-height;
        box-shadow: $box-shadow;
        z-index: 100;

        @include mobile {
            height: $header-mobile-height;
        }
    }

    &__logo {
        @include flex(center, center);
        position: absolute;
        inset: 0;
        pointer-events: none;

        img {
            height: 28px;
        }
    }

    &__menu {
        @include flex(center, space-between);
        height: 100%;
        font-size: 1.5rem;

        &__item ~ &__item {
            margin-left: 39px;

            @include tablet {
                margin-left: 20px;
            }

            @include mobile {
                margin-left: 10px;
            }
        }

        &__item.active {
            font-weight: 600;
            color: $main-color;
        }

        &__item:hover {
            color: $main-color;
        }

        &__left,
        &__right {
            @include flex(center, center);
            height: 100%;
        }

        &__right {
            font-size: 2.25rem;

            &__item {
                @include flex(center, center);

                @include tablet {
                    margin-left: 10px;
                }
            }

            @include mobile {
                font-size: 1.5rem;
            }
        }

        &__left {
            // PC 기본 스타일
            flex-direction: column;
            background-color: $main-bg;
            position: absolute;
            left: 0%; // 화면의 시작 위치를 12%로 조정
            top: 0;
            width: 370px;
            height: 100vh;
            justify-content: flex-start;
            padding-top: 30px;
            transform: translateX(-120%); // 화면 밖으로 완전히 숨김
            opacity: 0; // 초기 상태에서 보이지 않게 설정
            transition: transform 0.5s ease, opacity 0.5s ease; // opacity와 transform 동시 전환
            z-index: 1000; // 우선순위를 menu-bar 보다 높게잡음
            align-items: flex-start; // 자식 요소를 좌측 정렬
            justify-content: flex-start; // 자식 요소를 상단으로 정렬 (기본값)
            padding-left: 60px;
        
            &.active {
                transform: translateX(0); // 메뉴를 화면에 보이도록 함
                opacity: 1; // 메뉴가 활성화될 때 보이도록 함
            }
        
            &__item ~ &__item {
                margin-left: unset;
                margin-top: 20px;
            }
        
            &__close {
                display: block;
                position: absolute;
                right: 30px;
                font-size: 3rem;
            }

            // // 태블릿 및 모바일 스타일
            // @include tablet {
            //     flex-direction: column;
            //     background-color: $main-bg;
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            //     width: 70%;
            //     height: 100vh;
            //     justify-content: flex-start;
            //     padding-top: 30px;
            //     transform: translateX(-100%);
            //     transition: transform 0.5s ease;

            //     &.active {
            //         transform: translateX(0);
            //     }

            //     &__item ~ &__item {
            //         margin-left: unset;
            //         margin-top: 20px;
            //     }

            //     &__close {
            //         display: block;
            //         position: absolute;
            //         left: 20px;
            //         font-size: 2.5rem;
            //     }
            // }
        }

        &__mobile-toggle {
            display: block;
            font-size: 2.5rem;
            // @include tablet {
            //     display: block;
            //     font-size: 2.5rem;
            // }
        }
    }
}

.menu-bar {
    background-color: $main-bg;
    padding: 2px;
}

.menu-bar__list {
    list-style: none;
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
}

.menu-bar__item {
    position: relative;
    padding: 10px;
    border-bottom: 3px solid $main-color;
    margin-bottom: 5px;
    font-size: 1.25rem;

    span {
        cursor: pointer;
    }

    &:hover .menu-bar__sublist {
        max-height: 500px; /* 적절한 최대 높이 설정 */
        opacity: 1;
        visibility: visible;
    }
}

.menu-bar__sublist {
    list-style: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: $main-bg;
    padding: 0;
    margin: 0;
    box-shadow: $box-shadow;
    border: 2px solid $main-color;
    border-radius: 4px;
    width: 300px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease, opacity 1s ease, visibility 0.5s ease;
    opacity: 0;
    visibility: hidden;

    .menu-bar__subitem {
        padding: 10px 15px;
        background-color: $main-bg;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            text-decoration: none;
            color: $main-color;
            display: block;

            &:hover {
                color: $main-color;
            }
        }

        &:hover {
            background-color: $hover-bg;
            border-radius: 4px;
        }
    }
}



// .menu-bar__subitem {
//     padding: 5px 10px;
//     background-color: $main-bg;

//     a {
//         text-decoration: none;
//         color: $main-color;

//         &:hover {
//             color: $main-color;
//         }
//     }
// }