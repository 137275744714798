$main-bg: #fff;
// $main-color: #4267b2;
$main-color: #3C80AE;
$hover-bg: #aac5d8;

$txt-main-color: #000;
$txt-second-color: #676767;
$txt-white: #fff;

$btn-main-bg: $main-color;
$btn-main-color: #fff;

$header-height: 80px;
$header-shrink-height: 70px;
$header-tablet-height: 50px;
$header-mobile-height: 70px;


$box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

$colors: (
    // "blue": #4267b2,
    "blue": #3C80AE,
    "orange": #fbb96b,
    "pink": #fe7e73,
    "white": #fff,
    "black": #000,
    "main": $main-color,
    "red": #ff0000,
);

$mobile-width: 600px;
$tablet-width: 1024px;

$numbers: (0, 1, 2, 3, 4, 5, 6, 7, 8);
