.snsBtn{
  width: 30px;
  /* 이미지의 너비를 조정 */
  height: 30px;
  /* 이미지의 높이를 조정 */
  cursor: pointer;
}

.katalkCSbtn {
  align-items: center;
  text-align: center;
  position: fixed;
  bottom: 90px;
  right: 50px;
  width: 60px;
  /* 이미지의 너비를 조정 */
  height: 60px;
  /* 이미지의 높이를 조정 */
  cursor: pointer;
  z-index: 1000;
  /* 이미지가 다른 요소 위에 있도록 설정 */
  color: rgb(77, 127, 170);
}

.surveyBtn {
  align-items: center;
  text-align: center;
  position: fixed;
  bottom: 180px;
  right: 50px;
  width: 60px;
  /* 이미지의 너비를 조정 */
  height: 60px;
  /* 이미지의 높이를 조정 */
  cursor: pointer;
  z-index: 1000;
  /* 이미지가 다른 요소 위에 있도록 설정 */
  color: rgb(77, 127, 170);
}

.instagramBtn {
  align-items: center;
  text-align: center;
  position: fixed;
  bottom: 180px;
  right: 50px;
  width: 60px;
  /* 이미지의 너비를 조정 */
  height: 60px;
  /* 이미지의 높이를 조정 */
  cursor: pointer;
  z-index: 1000;
  /* 이미지가 다른 요소 위에 있도록 설정 */
  color: rgb(77, 127, 170);
}

.preprocess-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* 2개의 열을 동일한 너비로 배치 */
  gap: 30px;
  /* 두 열 사이의 간격을 설정 */

}

input[type="text"]:focus {
  outline: none;
  /* 기본 포커스 제거 */
  border: 2px solid rgb(63, 100, 243);
  /* 테두리를 두껍고 컬러를 rgb(63, 100, 243)로 변경 */
  box-shadow: 0 0 5px rgba(63, 100, 243, 0.5);
  /* 약간의 그림자 효과로 입체감 추가 */
}

.custom-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 0.5px solid grey; /* 모서리를 회색 테두리로 설정 */
  border-radius: 0.5px; /* 모서리를 약간 둥글게 */
  position: relative;
  cursor: pointer;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1); /* 기본 상태의 가벼운 그림자 */
  transition: box-shadow 0.3s ease, background-color 0.3s ease; /* 부드러운 전환 효과 */
}

.custom-checkbox:checked {
  background-color: white; /* 체크박스 선택 시 배경을 흰색으로 설정 */
  box-shadow: 2px 2px 6px rgba(63, 100, 243, 0.3); /* 체크 시 더 강한 그림자 */
  border-color: rgb(63, 100, 243); /* 체크 시 테두리 색상을 변경 */
}

.custom-checkbox:checked::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid rgb(63, 100, 243); /* 체크 표시를 rgb(63, 100, 243)으로 설정 */
  border-width: 0 2px 2px 0;
  transform: rotate(45deg); /* 체크 표시의 모양을 만듦 */
}


.file-upload-button {
  background-color: rgb(63, 100, 243);
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  height: 30px;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 30px;
  text-align: center;
}

.file-upload-button:hover {
  background-color: rgb(50, 90, 220);
  box-shadow: 4px 4px 12px rgba(63, 100, 243, 0.3);
}

.swal2-timer-progress-bar {
  background-color: rgb(63, 100, 243); /* 원하는 색상으로 변경 */
  height: 10px; /* 프로그레스바 높이 조절 */

}