.cart {
    @include flex(flex-start, flex-start);

    @include mobile {
        flex-wrap: wrap;
    }

    &__list {
        width: 60%;

        @include mobile {
            width: 100%;
        }
    }

    &__info {
        padding: 20px;
        margin-right: 30px;
        margin-top: 30px;
        box-shadow: $box-shadow;
        position: sticky;
        top: calc(#{$header-shrink-height} + 20px);

        @include mobile {
            width: 100%;
            margin-right: 0;
            margin-bottom: 30px;
            position: unset;
        }

        &__txt {
            & > * {
                margin-bottom: 10px;
            }

            p {
                font-size: 1.25rem;
            }

            &__price {
                @include flex(center, space-between);
                padding: 10px 0;
                font-size: 1.25rem;

                span:nth-child(2) {
                    font-size: 2rem;
                    color: $main-color;
                    font-weight: 600;
                }
            }
        }

        &__btn {
            & > * {
                margin-bottom: 20px;
            }
        }
    }

    &__item {
        @include flex(center, flex-start);
        margin-bottom: 20px;

        &__image {
            // width: 10%;
            margin-right: 20px;

            img {
                height: 150px;
            }
        }

        &__info {
            @include flex(center, space-between);
            flex-grow: 1;

            @include mobile {
                @include flex(flex-start, center);
                flex-direction: column;

                & > * {
                    margin-bottom: 10px;
                }
            }

            &__name {
                width: 40%;

                @include mobile {
                    width: 100%;
                }
            }

            &__name,
            &__price {
                font-size: 1.25rem;
            }
        }

        &__del {
            font-size: 1.5rem;

            i {
                cursor: pointer;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
}
