@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}

html {
    font-size: 13px;

    @include mobile {
        font-size: 11px;
    }
}

body {
    // font-family: "NanumSquareR, SCDream2, laundrygothic_Regular", "BMJUA_ttf", "Roboto", "sans-serif" ;
    font-family: "SUIT-Regular" ;
    letter-spacing: -0.05em;
    font-weight:normal;
    font-style:normal;
    // font-weight: 600;
    // font-weight: 100;

}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: unset;
}

a:hover {
    color: $main-color;
}

img {
    max-width: 100%;
}

@each $color, $val in $colors {
    .color-#{$color} {
        color: $val;
    }

    .bg-#{$color} {
        background-color: $val;
    }
}

@each $val in $numbers {
    .grid-col-#{$val} {
        grid-template-columns: repeat($val, 1fr);
    }
}

@each $val in $numbers {
    .grid-col-md-#{$val} {
        @include tablet {
            grid-template-columns: repeat($val, 1fr);
        }
    }
}

@each $val in $numbers {
    .grid-col-sm-#{$val} {
        @include mobile {
            grid-template-columns: repeat($val, 1fr);
        }
    }
}

.container {
    width: 100%;
    max-width: 1620px;
    padding: 0 12%;
    margin: auto;

    @include tablet {
        padding: 0 20px;
    }

    @include mobile {
        padding: 0 10px;
    }
}

.main {
    margin-top: $header-height;
    margin-bottom: 2rem;
    min-height: 100vh;

    @include tablet {
        margin-top: calc(#{$header-tablet-height} + 20px);
    }

    @include mobile {
        margin-top: calc(#{$header-mobile-height} + 10px);
    }
}

.grid {
    display: grid;
}

@font-face {
    font-family: 'BMJUA_ttf';
    src: url('../fonts/BMJUA_ttf.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'laundrygothic_Regular';
    src: url('../fonts/laundrygothic_Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SUIT-Regular';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}



// @font-face {
//     font-family: 'SCDream2';
//     src: url('../fonts/SCDream2.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'NanumSquareR';
//     src: url('../fonts/NanumSquareR.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }