.catalog {
    @include flex(flex-start, flex-start);

    @include tablet {
        flex-direction: column;
    }

    &__filter {
        width: 20%;

        @include tablet {
            width: max-content;
            height: 100vh;
            background-color: $main-bg;
            padding: 1rem;
            position: fixed;
            left: 0;
            top: $header-tablet-height;
            z-index: 100;
            box-shadow: $box-shadow;
            transform: translateX(-100%);
            transition: transform 0.3s ease;
        }

        @include mobile {
            top: $header-mobile-height;
        }

        &.active {
            transform: translateX(0);
        }

        &__widget {
            margin-bottom: 2rem;

            &__title {
                font-size: 1.25rem;
                font-weight: 600;
                text-transform: capitalize;
                margin-bottom: 16px;
            }

            &__content {
                color: $txt-second-color;

                &__item {
                    margin-bottom: 13px;
                }
            }
        }

        &__close {
            display: none;
            @include tablet {
                display: block;
                font-size: 2rem;
                margin-bottom: 1rem;
            }
        }
    }

    &__content {
        flex-grow: 1;

        @include tablet {
            width: 100%;
        }
    }

    &__filter__toggle {
        display: none;

        @include tablet {
            display: block;
            margin-bottom: 2rem;
        }
    }
}
