.tooltip-container {
    position: relative;
    display: inline-block;
    
}

.tooltip-circle {
    width: 30px;
    height: 30px;
    background-color: white;
    color: gray;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    cursor: pointer;
    border: 0.5px solid grey; /* 테두리 회색으로 설정 */
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1); /* 기본 상태에서 가벼운 그림자 */
    transition: box-shadow 0.3s ease; /* 부드러운 전환 효과 */

}

.tooltip-text {
    visibility: visible;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* 위치 조정 */
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
    opacity: 1;
}