.product-card {
    text-align: center;
    margin-bottom: 30px;

    &__image {
        padding-top: 60%;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;

        img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;

            &:nth-child(1) {
                transform: translateX(-50%) scale(1);
                transition: transform 0.5s ease;
            }

            &:nth-child(2) {
                transform: translateX(-50%) scale(0);
            }
        }

        &:hover img {
            &:nth-child(1) {
                transform: translateX(-50%) scale(0);
            }

            &:nth-child(2) {
                transform: translateX(-50%) scale(1);
                transition: transform 0.5s ease;
            }
        }
    }

    &__name {
        margin-bottom: 10px;
        color: $txt-main-color;
        font-size: 1.5rem;
        font-weight: bold;
    }

    &__price {
        color: $txt-second-color;
        font-size: 1.3rem;
        font-weight: 400;
        margin-bottom: 10px;
        // text-align: left; // 텍스트를 좌측 정렬합니다.

        &__old {
            color: $txt-second-color;
            margin-left: 20px;
            font-weight: 300;
        }
    }

    &__descriptionTag {
        color: rgb(70, 100, 166);
        font-size: 1.3rem;
        font-weight: 400;
        margin-bottom: 10px;
        // text-align: left; // 텍스트를 좌측 정렬합니다.
    }

    &__btn {
        @include tablet {
            display: none;
        }
    }
}
