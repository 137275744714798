.footer {
    padding: 5px 0;
    box-shadow: $box-shadow;
    font-size: 1.1rem;
    margin: 0; /* 필요한 경우, 좌우 마진 제거 */
    padding-left: 0; /* 좌측 패딩을 제거하여 텍스트를 좌측에 붙임 */
    background-color: black; /* 배경을 검정색으로 설정 */
    color: white; /* 텍스트를 흰색으로 변경 (가독성을 위해) */

    &__logo {
        height: 10px;
    }

    &__about {
        p {
            margin-bottom: 0.5rem;
        }
    }

    &__title {
        text-transform: uppercase;
        margin-bottom: 1rem;
        font-weight: 600;
    }

    &__content {
        p {
            margin-bottom: 1.25rem;
        }
    }
}