.custom-checkbox {
    @include flex(center, flex-start);

    cursor: pointer;

    &:hover {
        color: $main-color;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &__checkmark {
        margin-right: 10px;
        height: 15px;
        width: 15px;
        border: 1px solid $txt-second-color;

        i {
            transform: scale(0);
            transition: transform 0.3s ease;
        }
    }

    input:checked ~ &__checkmark {
        background-color: $main-color;
        border: 1px solid $main-color;
        color: $txt-white;
    }

    input:checked ~ &__checkmark > i {
        transform: scale(1);
    }
}
