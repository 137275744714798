@mixin flex($align-item, $justify-content) {
    display: flex;
    align-items: $align-item;
    justify-content: $justify-content;
}

@mixin mobile {
    @media only screen and (max-width: $mobile-width) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: $tablet-width) {
        @content;
    }
}
